<template>
  <el-container class="container">
    <div class="header">
      <div class="logo-container">
        <img src="@/assets/cs.png" alt="Logo" class="logo-img">
      </div>
      <div class="auth-buttons">
        <label type="primary" @click="login" class="login-btn">注册/登录</label>
        <!-- <el-button type="success" @click="register" class="register-btn">注册</el-button> -->
      </div>
    </div>
    <!-- <el-container> -->
    <el-aside class="chat-container">
      <!--对话框内容 -->
      <div class="chat-box">
        <!-- 聊天中间表头 -->
        <div class="tag-container">
          <span class="tag">{{ firstMessageTag }}</span>
        </div>
        <div ref="chatContainer" class="message-list">
          <!--消息列表 -->
          <div v-for="(message, index) in messages" :key="index" :class="['message', message.role]">
            <div class="avatar-container">
              <img :src="message.role === 'user' ? userAvatar : aiAvatar" class="avatar" alt="Avatar">
            </div>
            <div class="message-text">{{ message.content }}</div>
          </div>
        </div>
        <div class="input-container">
          <textarea placeholder="请输入您的问题..." v-model="newMessage" @keyup.enter="sendMessage"
            class="input-message"></textarea>
          <el-button type="success" round @click="send" :disabled="isSending" class="send-button">发送</el-button>
        </div>
      </div>
    </el-aside>
    <el-main class="image-container">
      <!--图片内容 -->
      <img src="@/assets/logo.png" alt="Placeholder Image" class="logo-image">
    </el-main>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      messages: [{ role: 'assistant', content: "你好，很高兴认识你！" }],
      newMessage: '',
      aiAvatar: require('@/assets/aiAvatar.png'),
      userAvatar: require('@/assets/userAvatar.jpg'),
      firstMessageTag: '开始对话吧 ~',
      isSending: false,
    };
  },
  methods: {
    // 发送消息
    send() {
      if (this.isSending) {
        return;
      }
      this.sendMessage();
    },
    // 创建一个通用的请求方法
    async sendMessage() {
      if (this.newMessage.trim() === '' || this.isSending) return;
      this.isSending = true;
      //添加用户消息到messages数组
      this.messages.push({ role: 'user', content: this.newMessage });
      //更新firstMessageTag
      this.updateFirstMessageTag(this.newMessage);
      //思考中
      this.messages.push({ role: 'assistant', content: '正在思考中...' });
      //发送整个messages数组到后端
      this.newMessage = '';

      try {
        const response = await fetch('http://127.0.0.1:5000/api/answer', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ messages: this.messages })
        });
        if (!response.ok) {
          throw new Error('发生错误！');
        }
        const data = await response.json();
        const aiResponse = data.choices[0].message.content;
        this.messages.pop(); //移除“思考中...”消息
        //添加AI的回复到messages数组
        this.messages.push({ role: 'assistant', content: aiResponse });
      } catch (error) {
        console.error('发送消息失败:', error);
        this.messages.pop(); //移除“思考中...”消息
        //显示错误信息
        //添加错误信息到messages数组
        this.isSending = false;
        this.messages.push({ role: 'assistant', content: '遇到错误，请稍后再试。' });
      }
      finally {
        // 请求完成后启用发送按钮
        this.isSending = false;
      }
    },


    // 滚动条自动下滑到最下面
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.chatContainer;
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      });
    },

    // 更新标签
    updateFirstMessageTag(message) {
      if (this.messages.length === 2 && this.messages[1].role === 'user') {
        this.firstMessageTag = message.length > 10 ? message.substring(0, 10) + '...' + "∧" : message + "∧";
      }
    },
    login() {
      console.log('登录');
    },
    register() {
      console.log('注册');
    }
  },
  created() {
  },
  mounted() {
    this.scrollToBottom();
  },

  watch: {
    messages() {
      this.scrollToBottom();
    }
  },
  //每次页面渲染完之后滚动条在最底部
  updated: function () {
    this.scrollToBottom();
  },
};
</script>

<style scoped>
.container {
  display: flex;
  width: 100%;
  margin: 0 auto;
  /* height: 500px; */
  height: 100vh;
  border: 1px solid #ccc;
  border-radius: 5px;
  /* flex-direction: column; */
}

.message-list {
  height: 300px;
  overflow-y: auto;
  border-bottom: 1px solid #ccc;
  margin-bottom: 8px;
  padding-bottom: 10px;
}

.image-container {
  flex: 1;
  /*右边占三分之一 */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.logo-image {
  max-width: 100%;
  max-height: 100%;
  opacity: 0.7;
}

/* 中间标签样式 */
.tag-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tag {
  max-width: 100px;
  /*限制标签的最大宽度 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* background-color: #f0f0f0; */
  /* padding:5px 10px; */
  /* border-radius:10px; */
}

.chat-container {
  position: relative;
  /* 为伪元素提供定位上下文 */
  flex: 3;
  /*左边占三分之二 */
  border-right: 2px solid #ccc;
  height: 100%;
  /* background-color: #918d8d; */

}

.chat-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-image: url('../assets/logo.png'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.5;
  /* 背景图片的透明度 */
  z-index: -1;
  /* 确保伪元素在输入框下面 */
}


.chat-box {
  padding: 10px;
  height: 90%;
  /*确保高度不超过最外层容器 */
  display: flex;
  flex-direction: column;
}

.message-list {
  flex: 1;
  /*让消息列表占据剩余空间 */
  overflow-y: auto;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.user {
  align-items: flex-end;
}

.avatar-container {
  margin-bottom: 5px;
}

.avatar {
  width: 40px;
  /*限制头像的宽度 */
  height: 40px;
  /*限制头像的高度 */
  border-radius: 50%;
  /*使头像显示为圆形 */
}

/* 用户聊天 */
.message-text {
  padding: 10px;
  border-radius: 10px;
  background-color: #101e7a;
  color: #f9f9f9;
  text-align: left;
}

/* ai回复 */
.assistant .message-text {
  background-color: #f5f4f4;
  color: #101e7a;
}

.input-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  width: 100%;
}

.input-message {
  flex: 1;
  margin-right: 10px;
  padding: 10px;
  height: 70px;
  /*调整输入框高度 */
  width: 100%;
  box-sizing: border-box;
  resize: none;
  /*防止用户调整文本域的大小 */
  border-radius: 10px;
  background-color: transparent;
}

.send-button {
  /* padding:10px 20px; */
  height: 50px;
  width: 100px;
  font-size: 18px;
  background-color: #101e7a;
  border-radius: 20px;
  cursor: pointer;
  color: #f9f9f9;
  font-weight: bold;
  flex: 00 auto;
  /*确保按钮不占宽度 */
}

/* 顶部样式 */
.header {
  position: fixed;
  /* display: flex; */
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 60px;
  /* background-color: #e21717; */
  z-index: 1000;
}

.logo-container {
  flex: 1;
}

.logo-img {
  float: left;
  max-width: 100px;
  max-height: 50px;
  width: 100px;
  height: 60px;
  /* background-color: #e41818; */
}

.auth-buttons {
  display: flex;
  gap: 10px;
}

.login-btn,
.register-btn {
  text-align: center;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
}

/* 顶部样式 */
</style>